import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScholarshipForm from '../components/Scholarship-Form/Scholarship-form';

//Author :Upanshu

const meta = {
  title: 'Scholarship Programme',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function ScholarshipFormPage() { 
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ScholarshipForm /> 
    </React.Fragment>
  );
}
