import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import supabase from '../../createClient'; 

export default function FreeResources() {
  const [freeResources, setFreeResources] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    fetchFreeResources(selectedCategory);
  }, [selectedCategory]);

  const fetchFreeResources = async (category) => {
    try {
      const { data, error } = await supabase
        .from('resources')
        .select('*');
        
      if (error) {
        throw error;
      }

      const filteredFreeResources = category 
        ? data.filter(resource => resource.category === category) 
        : data;

      setFreeResources(filteredFreeResources);
    } catch (error) {
      console.error('Error fetching Resources:', error);
    }
  };

  const filterFreeResources = (category) => {
    setSelectedCategory(category);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <React.Fragment>
      <section id="Free-Resources" className="py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-center text-3xl lg:text-5xl font-bold font-heading mb-4">UPSC &amp; State PCS Free Resources</h1>
          <p className="text-center text-gray-600 mb-20">Utilize Dikshant IAS Free Resources for your preparation journey</p>
          <div>
            <Slider {...settings}>
              {freeResources.map((resource) => (
                <div key={resource.id} className="p-4">
                  <div className="relative" style={{ height: '300px' }}>
                    <div className="border border-gray-100 shadow rounded-3xl overflow-hidden w-full h-full">
                      <a href={resource.href} target="_blank" rel="noopener noreferrer" className="block w-full h-full">
                        <img className="shadow-lg w-full h-full object-cover" src={resource.image} alt={resource.title} />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>  
        </div>
      </section>
    </React.Fragment>
  );
}
