import React from 'react';

export default function NotFoundPage() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-11 overflow-hidden bg-blue-900 "><div className="container mx-auto px-4">
    <h1 className="text-center text-white text-4xl lg:text-5xl font-bold font-heading mb-10">Oops. Page not found.</h1>
    <div className="flex justify-center mb-20"><a className="w-full sm:w-auto text-center h-14 inline-flex items-center justify-center py-4 px-6 rounded-full bg-white border border-gray-200 shadow text-sm font-semibold hover:bg-white focus:ring focus:ring-red-200 transition duration-200" href="/">Back to Home</a></div>
    <div className="flex items-center justify-center gap-4 sm:gap-8 lg:gap-16">
      <div>
        <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24 mx-auto" />
        <div className="flex justify-between mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="flex gap-1 lg:gap-2 mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24 ml-auto" />
      </div>
      <div>
        <div className="flex gap-1 lg:gap-2 mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="flex justify-between mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="flex gap-1 lg:gap-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
      </div>
      <div>
        <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24 mx-auto" />
        <div className="flex justify-between mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="flex gap-1 lg:gap-2 mb-2">
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
          <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24" />
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg sm:rounded-3xl w-6 sm:w-12 lg:w-24 h-6 sm:h-12 lg:h-24 ml-auto" />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

