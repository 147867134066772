import React, { useEffect, useState } from 'react';
import supabase from '../createClient'; 

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchBlogs = async () => {
      const { data, error } = await supabase
        .from('Articles') 
        .select('*')
        .eq('category', 'What to Read in the Indian Express')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching blogs:', error);
        setError(error.message); 
      } else {
        console.log('Fetched blogs:', data); 
        setBlogs(data || []); 
      }
    };

    fetchBlogs();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="bg-white min-h-screen">
      <header className="bg-blue-900 text-white py-4">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl font-bold">What to Read In The Indian Express</h1>
          <p className="mt-2">Whatsapp 98103-86285 For Details</p>
        </div>
      </header>
      <main className="container mx-auto mt-8">
        {error && (
          <div className="text-red-500 text-center mb-4">
            Error: {error}
          </div>
        )}
        <div className="flex flex-wrap -mx-4">
          {blogs.length === 0 ? (
            <p className="text-center w-full">No articles found.</p>
          ) : (
            blogs.map((articles) => (
              <div key={articles.id} className="w-full md:w-1/2 lg:w-1/3 p-4">
                <a href={"what-to-read-in-indian-express/"+articles.id || '#'}> 
                  <div className="bg-white border border-gray-200 hover:border-red-500 transition duration-200 rounded-2xl h-full p-3">
                    <div className="py-6">
                      <div className="flex flex-wrap items-center gap-3 mb-3">
                        <p className="py-1 px-2 rounded-md bg-red-500 border border-gray-100 text-sm font-medium text-white">
                          {formatDate(articles.created_at) || 'Date not available'} 
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 4 4"
                          fill="none"
                        >
                          <line
                            x1="2"
                            y1="0"
                            x2="2"
                            y2="4"
                            stroke="#B8B8B8"
                            strokeWidth="0.5"
                          />
                        </svg>
                        <div className="py-1 px-2 rounded-md border border-gray-100 text-xs font-medium text-gray-700">
                          {articles.category || 'Category not available'} {/* Fallback for category */}
                        </div>
                      </div>
                      <h2 className="text-xl font-bold font-heading mb-3 max-w-xs max-w-xl title">
                        {articles.title || 'Title not available'} {/* Fallback for title */}
                      </h2>
                      <div className="flex gap-4 mx-4">
                        <a
                          className="h-10 inline-flex items-center mt-4 justify-center w-full sm:w-auto text-center py-3 px-4 rounded-full bg-red-600 hover:bg-red-500 text-white text-sm font-semibold transition duration-200"
                          href={"what-to-read-in-indian-express/"+articles.id || '#'}
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))
          )}
        </div>
      </main>
    </div>
  );
};

export default BlogPage;
