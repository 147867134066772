import React, { useState, useEffect } from 'react';

const LOGO_SRC = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg_NyDLrUdD1cWeQkS5kojWfJH7SmMvQ9F7D-li4V_&s";
const MENU_ITEMS = [
    { 
        title: "All Courses", 
        isDropdown: true, 
        dropdownItems: [
            { title: "Offline Mode", href: "/offline-mode" },
            { title: "Online Mode", href: "/online-mode" },
        ] 
    },
    {  title: "Current Affairs", 
        isDropdown: true, 
        dropdownItems: [
            { title: "What to read in the Hindu", href: "/what-to-read-in-the-hindu" },
            { title: "What to read in the Indian Express", href: "/what-to-read-in-indian-express" },
            { title: "Editorial Analysis", href: "/editorial-analysis" },
            { title: "Daily Current Affairs Analysis", href: "/daily-current-affairs-analysis" },
            { title: "Important Facts of the Day", href: "/facts-of-the-day" },
        ] 
    },
    { title: "Scholarship Programme", href: "/Scholarship" },
    { title: "▣ Live Demo", href: "https://www.youtube.com/@DikshantIAS/videos", className: "blink" },
];

export default function Header() {
    const [isNavbarMenuOpen, setIsNavbarMenuOpen] = useState(false);
    const [dropdownState, setDropdownState] = useState({});
    const [blink, setBlink] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setBlink(prevBlink => !prevBlink);
        }, 500); 

        return () => clearInterval(intervalId);
    }, []);

    const toggleNavbar = () => {
        setIsNavbarMenuOpen(!isNavbarMenuOpen);
    };

    const toggleDropdown = (index) => {
        setDropdownState((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <header>
            <section className="overflow-visible relative shadow-md">
                <div className="h-5" style={{ background: 'linear-gradient(99deg, #C60000 6.68%, #FFFF 75%, #C60000 93.31%)' }} />
                <div className="bg-white relative z-10 shadow-md">
                    <div className="container px-4 mx-auto">
                        <div className="flex items-center justify-between py-6 relative z-10">
                            <a href="/">
                                <img src={LOGO_SRC} alt="Dikshant IAS" width="150px" />
                            </a>
                            <NavMenu
                                dropdownState={dropdownState}
                                toggleDropdown={toggleDropdown}
                                blink={blink}
                            />
                            <Buttons />
                            <MobileMenuButton toggleNavbar={toggleNavbar} />
                        </div>

                        <MobileMenu
                            isNavbarMenuOpen={isNavbarMenuOpen}
                            toggleNavbar={toggleNavbar}
                            dropdownState={dropdownState}
                            toggleDropdown={toggleDropdown}
                            blink={blink}
                        />
                    </div>
                </div>
            </section>
        </header>
    );
}

const NavMenu = ({ dropdownState, toggleDropdown, blink }) => (
    <ul className="hidden lg:flex items-center gap-8">
        {MENU_ITEMS.map((item, index) =>
            item.isDropdown ? (
                <NavItemDropdown
                    key={index}
                    title={item.title}
                    dropdownItems={item.dropdownItems}
                    isDropdownOpen={dropdownState[index]}
                    toggleDropdown={() => toggleDropdown(index)}
                />
            ) : (
                <NavItem 
                    key={index} 
                    title={item.title} 
                    href={item.href} 
                    className={item.className === "blink" ? blink ? "text-red-500" : "text-white" : ""}
                />
            )
        )}
    </ul>
);

const NavItem = ({ title, href, className }) => (
    <li className={`hover:text-opacity-70 transition duration-200 ${className}`}>
        <a href={href}>{title}</a>
    </li>
);

const NavItemDropdown = ({ title, dropdownItems, isDropdownOpen, toggleDropdown }) => (
    <li className="relative">
        <a
            className="flex items-center flex-wrap gap-2 group cursor-pointer"
            href="#"
            onClick={toggleDropdown}
        >
            <span className="group-hover:text-opacity-70 transition duration-200">
                {title}
            </span>
            <div className="group-hover:text-opacity-70 transition duration-200">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M13 5.5L8 10.5L3 5.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </a>
        {isDropdownOpen && (
            <ul className="absolute top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-2 z-20">
                {dropdownItems.map((item, index) => (
                    <DropdownItem key={index} title={item.title}  href={item.href}/>
                ))}
            </ul>
        )}
    </li>
);

const DropdownItem = ({ title, href }) => (
    <li>
        <a
            href={href}
            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
        >
            {title}
        </a>
    </li>
);

const Buttons = () => (
    <div className="hidden lg:flex gap-4">
        <a
            className="py-3 px-5 rounded-full border border-gray-200 shadow text-sm font-semibold hover:bg-gray-50 focus:ring focus:ring-orange-200 transition duration-200"
            href="https://play.google.com/store/apps/details?id=com.chalksnboard_dikshant_ias_pcs&hl=en&gl=US"
        >
            Login to our App
        </a>
        <a
            className="py-3 px-5 rounded-full bg-red-600 border shadow text-sm font-semibold text-white hover:bg-red-500 focus:ring focus:ring-purple-800 transition duration-200"
            href="tel:+917428092240"
        >
            Call Us for Admissions
        </a>
    </div>
);

const MobileMenuButton = ({ toggleNavbar }) => (
    <div className="lg:hidden">
        <a href="#" onClick={toggleNavbar}>
            <svg
                className="navbar-burger text-orange-500"
                width={51}
                height={51}
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width={56} height={56} rx={28} fill="currentColor" />
                <path
                    d="M37 32H19M37 24H19"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </a>
    </div>
);

const MobileMenu = ({
    isNavbarMenuOpen,
    toggleNavbar,
    dropdownState,
    toggleDropdown,
    blink,
}) => (
    <div
        className={`navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50 ${
            isNavbarMenuOpen ? 'block' : 'hidden'
        }`}
    >
        <div
            className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"
            onClick={toggleNavbar}
        />
        <nav className="relative z-10 px-9 py-8 h-full overflow-y-auto bg-white flex flex-col justify-between">
            <div className="flex items-center justify-between">
                <a href="#">
                    <img src={LOGO_SRC} alt="Logo" />
                </a>
                <a className="navbar-burger" href="#" onClick={toggleNavbar}>
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 18L18 6M6 6L18 18"
                            stroke="#111827"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </a>
            </div>
            <div className="my-8">
                <ul>
                    {MENU_ITEMS.map((item, index) =>
                        item.isDropdown ? (
                            <MobileNavItemDropdown
                                key={index}
                                title={item.title}
                                dropdownItems={item.dropdownItems}
                                isDropdownOpen={dropdownState[index]}
                                toggleDropdown={() => toggleDropdown(index)}
                            />
                        ) : (
                            <NavItem 
                                key={index} 
                                title={item.title} 
                                href={item.href} 
                                className={item.className === "blink" ? blink ? "text-red-500" : "text-white" : ""}
                            />
                        )
                    )}
                </ul>
            </div>
            <div className="pb-8">
                <Buttons />
            </div>
        </nav>
    </div>
);

const MobileNavItemDropdown = ({
    title,
    dropdownItems,
    isDropdownOpen,
    toggleDropdown,
}) => (
    <li>
        <a
            className="flex items-center justify-between mb-1 group cursor-pointer"
            onClick={toggleDropdown}
            href="#"
        >
            <span className="font-heading font-medium text-lg text-gray-900">
                {title}
            </span>
            <div className="text-gray-900">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M13 5.5L8 10.5L3 5.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </a>
        {isDropdownOpen && (
            <ul className="pl-4">
                {dropdownItems.map((item, index) => (
                    <DropdownItem key={index} title={item.title} href={item.href} />
                ))}
            </ul>
        )}
    </li>
);
