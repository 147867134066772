import React, { useState } from 'react';
import  supabase  from '../../createClient'; // Ensure this path is correct

function Admission() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    applicant_name: '',
    mobile: '',
    email: '',
    course_type: '',
    medium: '',
    category: ''
  });

  const [isFormComplete, setIsFormComplete] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    checkFormCompletion({ ...formData, [name]: value });
  };

  const checkFormCompletion = (data) => {
    const isComplete = Object.values(data).every(field => field !== '');
    setIsFormComplete(isComplete);
  };

  const handleSubmit = async () => {
    try {
      if (isFormComplete) {
        // Insert data into Supabase table
        const { data, error } = await supabase
          .from('Admission Records') // Updated table name
          .insert([formData]);

        if (error) {
          throw error;
        }

        console.log('Submission successful:', data);
        // Close the modal and reset form
        toggleModal();
        setFormData({
          applicant_name: '',
          mobile: '',
          email: '',
          course_type: '',
          medium: '',
          category: ''
        });
      } else {
        alert('Please complete the form before submitting.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="absolute bottom-10 left-12 top-12 z-50 h-screen">
      <button
        type="button"
        className="btn btn-primary btn-lg z-50 bg-red-600 rounded-full fixed rotate-90 bg-gray-900 text-white font-bold py-3 px-3"
        onClick={toggleModal}
      >
        Open Application Form
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
          onClick={toggleModal}
        >
          <div
            className="relative w-11/12 max-w-2xl mx-auto p-5 border shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header flex items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-red-600">
              <h4 className="text-2xl font-bold text-white text-center w-full">
                Application for Admission to Dikshant IAS
              </h4>
              <button
                type="button"
                className="text-white text-2xl"
                onClick={toggleModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body p-4">
              <form className="form-horizontal">
                <div className="form-group mb-4">
                  <label
                    htmlFor="applicant_name"
                    className="control-label block text-gray-600 text-sm font-bold mb-2"
                  >
                    Applicant's Name:
                  </label>
                  <input
                    type="text"
                    name="applicant_name"
                    id="applicant_name"
                    className="form-control border rounded w-full py-2 px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter your full name"
                    value={formData.applicant_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="input-group flex items-center mb-4">
                  <span className="bg-red-600 text-white px-3 py-2 rounded-l-md">
                    +91
                  </span>
                  <input
                    type="text"
                    name="mobile"
                    className="form-control border rounded-r w-full py-2 px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"
                    id="mobile"
                    maxLength="10"
                    placeholder="Enter your mobile number"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label
                    htmlFor="email"
                    className="control-label block text-gray-700 text-sm font-bold mb-2"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter your email address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <label
                    htmlFor="course_type"
                    className="control-label block text-gray-700 text-sm font-bold mb-2"
                  >
                    Course Type:
                  </label>
                  <select
                    name="course_type"
                    id="course_type"
                    className="form-control border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData.course_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>
                <div className="form-group mb-4">
                  <label
                    htmlFor="medium"
                    className="control-label block text-gray-700 text-sm font-bold mb-2"
                  >
                    Select The Medium:
                  </label>
                  <select
                    name="medium"
                    id="medium"
                    className="form-control border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData.medium}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                  </select>
                </div>
                <div className="form-group mb-4">
                  <label
                    htmlFor="category"
                    className="control-label block text-gray-700 text-sm font-bold mb-2"
                  >
                    Select Category:
                  </label>
                  <select
                    name="category"
                    id="category"
                    className="form-control border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData.category}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="IAS/UPSC Complete Foundation Course">
                      IAS/UPSC Complete Foundation Course
                    </option>
                    <option value="GS PRE & MAINS Foundation Course">
                      GS PRE & MAINS Foundation Course
                    </option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer flex flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="btn btn-default bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded"
                onClick={toggleModal}
              >
                Close
              </button>
              <button
                type="button"
                className={`btn btn-primary text-white font-bold py-2 px-4 rounded ml-2  ${isFormComplete ? 'bg-red-600' : 'bg-red-600'}`}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Admission;
