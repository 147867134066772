import React from 'react';

const AboutUs = () => {
    return (
        <div className="container mx-auto py-12 px-6">
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold">Dikshant IAS: A Premier Civil Services Exam Coaching Institute</h1>
            </div>

            <div className="mb-12">
                <p className="text-lg leading-relaxed">
                    Dikshant IAS is India’s one of the premier and fastest-growing coaching Institutes for the Civil Services Examination
                    which is a nationwide competitive examination in India conducted by the Union Public Service Commission (UPSC) for
                    recruitment to various Civil Services of the Government of India.
                </p>
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-semibold mb-4">Our Vision</h2>
                <blockquote className="text-lg italic mb-4">"You can't cross the sea merely by standing and staring at the water." – Rabindranath Tagore</blockquote>
                <ul className="list-disc list-inside space-y-4">
                    <li>Our main objective is to guide the individual to grasp information in such a professional way that it becomes a cakewalk for him/her to crack the prestigious exam.</li>
                    <li>Because Dikshant IAS prepares you for all three stages of the UPSC Exam: the preliminary exam, the main exam, and the personality test in a way that is more convenient and efficient.</li>
                    <li>You must understand that our objective is to guide you to achieve the goal that you have always been challenged to achieve.</li>
                </ul>
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-semibold mb-4">Thoughts</h2>
                <p className="text-lg leading-relaxed">
                    Our experienced faculties have adopted an examination-oriented approach by closely monitoring the trends of the examination.
                    We regularly update and design our approach and strategy according to the latest trends observed according to the syllabus.
                    Our unique course design and modular teaching techniques have proven to be effective and efficient.
                </p>
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
                <p className="text-lg leading-relaxed">
                    Our mission is to groom aspirants for the Civil Services Examination who can become the strength of the Indian administrative system.
                    We focus on developing sound academic knowledge with an innovative perspective of mind.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
