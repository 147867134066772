// src/pages/ArticleDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../createClient';
import NotFoundPage from './NotFoundPage';

const ArticleDetailPage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        // Fetch the article
        const { data: articleData, error: articleError } = await supabase
          .from('Articles')
          .select('*')
          .eq('id', id)
          .single();

        if (articleError) {
          throw new Error('Article not found.');
        }

        // Fetch related articles
        const { data: relatedArticlesData, error: relatedArticlesError } = await supabase
          .from('Articles')
          .select('*')
          .neq('id', id)
          .limit(3); // Adjust the limit as needed

        if (relatedArticlesError) {
          throw new Error('Error fetching related articles.');
        }

        setArticle(articleData);
        setRelatedArticles(relatedArticlesData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!article) {
    return <NotFoundPage />;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatContent = (content) => {
    let formattedContent = content.replace(/\n/g, '<br />');
  
    formattedContent = formattedContent.replace(/^\* (.+)$/gm, '<li>$1</li>'); 
    formattedContent = formattedContent.replace(/^\d+\. (.+)$/gm, '<li>$1</li>'); 
  
    formattedContent = formattedContent.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>'); 

    formattedContent = formattedContent.replace(/(<br \/>){2,}/g, '</p><p>'); 
    formattedContent = `<p>${formattedContent}</p>`; 
  
    return formattedContent;
  };
  

  return (
    <section className="overflow-hidden py-24 bg-white">
      <div className="container mx-auto px-4">
        <a
          className="text-gray-500 group mb-12 inline-flex items-center gap-2 flex-wrap hover:text-gray-700 transition duration-200"
          href="/"
        >
          <div className="transition duration-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.4167 10H5M5 10L10 5M5 10L10 15"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <span className="transition duration-200 font-bold">
            {article.category}
          </span>
        </a>

        <div className="flex flex-wrap -mx-4 mb-10 shadow-md">
          <div className="w-full lg:w-1/2 p-4">
            <div className="py-7">
              <h1 className="text-4xl lg:text-4xl font-bold mb-2 text-gray-800">
                {article.title}
              </h1>
              <br />
              <div className="flex items-center gap-3">
                <div className="rounded-md bg-gray-200 py-1 px-3">
                  <span className="text-gray-700 text-xs font-medium">
                    By: Team Dikshant IAS 
                  </span>
                </div>
                <div className="flex items-center gap-3 font-bold text-red-500">
                  <div className="h-6 w-px bg-gray-300"></div>
                  <span>Date: {formatDate(article.created_at)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Article Content Section */}
        <div className="flex flex-wrap -mx-4">
          <div className="w-full p-4">
            <div className="text-gray-700 mb-6 prose" dangerouslySetInnerHTML={{ __html: formatContent(article.content) }}>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleDetailPage;
