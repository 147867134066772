import React from 'react';

export default function CallToAction() {
    return (
        <React.Fragment>
            <>
                <section className="overflow-hidden">
  <div className="container mx-auto px-4 relative">
    <div className="hidden md:block absolute top-0 left-0">
      <svg xmlns="http://www.w3.org/2000/svg" width={500} height={500} viewBox="0 0 500 500" fill="none">
        <path d="M0.5 498.311V304.054C0.5 136.406 136.406 0.5 304.054 0.5H498.311C498.967 0.5 499.5 1.03289 499.5 1.68919V136.561C499.5 137.217 498.967 137.75 498.311 137.75H342.061C290.59 137.75 239.516 163.123 201.319 201.319C163.123 239.516 137.75 290.59 137.75 342.061V498.311C137.75 498.967 137.217 499.5 136.561 499.5H1.68919C1.03249 499.5 0.5 498.967 0.5 498.311Z" fill="url(#paint0_linear_170_13470)" stroke="#F0F0F0" />
        <defs><linearGradient id="paint0_linear_170_13470" x1={0} y1={500} x2={500} y2={0} gradientUnits="userSpaceOnUse"><stop stopColor="#F8F8F8"><stop offset={1} stopColor="#F8F8F8" stopOpacity={0} /></stop></linearGradient></defs>
      </svg>
    </div>
    <div className="hidden md:block absolute top-0 right-0">
      <svg xmlns="http://www.w3.org/2000/svg" width={500} height={500} viewBox="0 0 500 500" fill="none">
        <path d="M499.5 1.68919V195.946C499.5 363.594 363.594 499.5 195.946 499.5H1.68919C1.03289 499.5 0.5 498.967 0.5 498.311V363.439C0.5 362.783 1.03289 362.25 1.68919 362.25H157.939C209.41 362.25 260.484 336.877 298.681 298.681C336.877 260.484 362.25 209.41 362.25 157.939V1.68919C362.25 1.03289 362.783 0.5 363.439 0.5H498.311C498.967 0.5 499.5 1.03289 499.5 1.68919Z" fill="url(#paint0_linear_170_13469)" stroke="#F0F0F0" />
        <defs><linearGradient id="paint0_linear_170_13469" x1="499.998" y1="2.85805e-05" x2="8.49831" y2={500} gradientUnits="userSpaceOnUse"><stop stopColor="#F8F8F8"><stop offset={1} stopColor="#F8F8F8" stopOpacity={0} /></stop></linearGradient></defs>
      </svg>
    </div>
    <div className="py-32 relative z-10">
      <h1 className="text-center text-4xl lg:text-5xl font-bold font-heading mb-4 max-w-md lg:max-w-xl mx-auto">
        <span>Unite with ambitious</span>
        <span />
        <span className="text-red-500"> IAS aspirants.</span>
        <span />
      </h1>
      <p className="text-gray-500 text-center mb-10">Elevate your ambition by joining a vibrant community of IAS achievers</p>
      <div className="flex justify-center flex-wrap gap-4">
          <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} viewBox="0 0 21 20" fill="none">
            <path d="M5.49996 10H15.9166M15.9166 10L10.9166 5M15.9166 10L10.9166 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

