import React from 'react';
import { useParams } from 'react-router-dom';

const selectionData = {
  'UPSC-Results-2024': {
    title: 'UPSC-Results-2024',
    imageUrl: 'https://dikshantias.com/images/result/UPSC-Result-2022.jpeg',
  },
  'UPSC Results 2023': {
    title: 'UPSC Results 2023',
    imageUrl: '/images/selections_2023.png',
  },
  'UPSC Results 2022': {
    title: 'UPSC Results 2022',
    imageUrl: '/images/selections_2022.png',
  },
  // Add more titles and data as needed
};

const SelectionPage = () => {
  const { title } = useParams(); // Get the title from the URL
  const data = selectionData[title] || {
    title: 'Selection Not Found',
    imageUrl: '/images/default_selection.png',
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center">
      <div className="bg-blue-900 text-white text-2xl py-4 w-full text-center">
        {data.title}
      </div>
      <div className="mt-4">
        <button className="bg-blue-900 text-white py-2 px-4 rounded-lg">
          Whatsapp 98103-86285 For Details
        </button>
      </div>
      <div className="mt-4 flex justify-center">
        <div className="w-full max-w-3xl">
          <img
            src={data.imageUrl}
            alt={data.title}
            className="w-full h-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectionPage;
