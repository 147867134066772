import React, { useState } from 'react';

export default function ScholarshipForm() {
  const [formData, setFormData] = useState({
    mobile: '',
    email: '',
    address: '',
    gender: '',
    category: '',
    medium: '',
    mode: '',
    familyincome: '',
    education_qualification: '',
    accept: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isAnyFieldEmpty = Object.values(formData).some(value => value === '');

    if (isAnyFieldEmpty) {
      alert('Please fill in all fields.');
    } else {
      console.log('Form submitted:', formData);
      // intigrate crm here
    }
  };


  // Author: Upanshu

  return (
    <React.Fragment>
      <>
        <section className="overflow-hidden">
          <div className="container px-4 mx-auto pt-16 pb-20">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 p-4">
                <div className="max-w-xl mx-auto">
                  <h1 className="text-3xl lg:text-5xl font-bold font-heading mb-4">Scholarship Form</h1>
                  <p className="text-gray-600 mb-10">Join Your IAS journey with Dikshant IAS.</p>
                  <div className="mb-10 flex items-center gap-1">
                    <div className="bg-orange-200 rounded-3xl h-px w-3" />
                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={3} viewBox="0 0 3 3" fill="none">
                      <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={3} viewBox="0 0 3 3" fill="none">
                      <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width={3} height={3} viewBox="0 0 3 3" fill="none">
                      <circle cx="1.5" cy="1.5" r="1.5" fill="#FFA632" />
                    </svg>
                    <div className="bg-orange-200 rounded-3xl h-px w-3" />
                  </div>
                  <div className="flex items-center flex-wrap gap-3 mb-4">
                    <div className="bg-red-500 rounded-xl p-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                        <path d="M18.3333 17.7083H1.66666C1.32499 17.7083 1.04166 17.9916 1.04166 18.3333C1.04166 18.675 1.32499 18.9583 1.66666 18.9583H18.3333C18.675 18.9583 18.9583 18.675 18.9583 18.3333C18.9583 17.9916 18.675 17.7083 18.3333 17.7083Z" fill="white" />
                        <path d="M14.1667 1.66669H5.83333C3.33333 1.66669 2.5 3.15835 2.5 5.00002V18.3334H17.5V5.00002C17.5 3.15835 16.6667 1.66669 14.1667 1.66669ZM8.33333 14.375H5.83333C5.49167 14.375 5.20833 14.0917 5.20833 13.75C5.20833 13.4084 5.49167 13.125 5.83333 13.125H8.33333C8.675 13.125 8.95833 13.4084 8.95833 13.75C8.95833 14.0917 8.675 14.375 8.33333 14.375ZM8.33333 10.625H5.83333C5.49167 10.625 5.20833 10.3417 5.20833 10C5.20833 9.65835 5.49167 9.37502 5.83333 9.37502H8.33333C8.675 9.37502 8.95833 9.65835 8.95833 10C8.95833 10.3417 8.675 10.625 8.33333 10.625ZM8.33333 6.87502H5.83333C5.49167 6.87502 5.20833 6.59169 5.20833 6.25002C5.20833 5.90835 5.49167 5.62502 5.83333 5.62502H8.33333C8.675 5.62502 8.95833 5.90835 8.95833 6.25002C8.95833 6.59169 8.675 6.87502 8.33333 6.87502ZM14.1667 14.375H11.6667C11.325 14.375 11.0417 14.0917 11.0417 13.75C11.0417 13.4084 11.325 13.125 11.6667 13.125H14.1667C14.5083 13.125 14.7917 13.4084 14.7917 13.75C14.7917 14.0917 14.5083 14.375 14.1667 14.375ZM14.1667 10.625H11.6667C11.325 10.625 11.0417 10.3417 11.0417 10C11.0417 9.65835 11.325 9.37502 11.6667 9.37502H14.1667C14.5083 9.37502 14.7917 9.65835 14.7917 10C14.7917 10.3417 14.5083 10.625 14.1667 10.625ZM14.1667 6.87502H11.6667C11.325 6.87502 11.0417 6.59169 11.0417 6.25002C11.0417 5.90835 11.325 5.62502 11.6667 5.62502H14.1667C14.5083 5.62502 14.7917 5.90835 14.7917 6.25002C14.7917 6.59169 14.5083 6.87502 14.1667 6.87502Z" fill="white" />
                      </svg>
                    </div>
                    <h2 className="text-xl font-bold font-heading"> Contact with Dikshant IAS </h2>
                  </div>
                  
                  <p className="text-gray-600 mb-6">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <defs>
      <path id="phone-icon" d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z"/>
    </defs>
    <use href="#phone-icon" fill="currentColor"/>
  </svg>
  Phone IVR : +91 7428092240
</p>


                  <p className="text-gray-600 mb-6"> English : +91 9958330200</p>

                  <p className="text-gray-600 mb-6"> Hindi : +91 9910889804</p>

                  <p className="text-gray-600 mb-6"> WhatsApp : +91 9810386285</p>
                  <p className="text-gray-600 mb-6">289 Ground Floor, Johar, near Dusehra Ground, Dhaka Village, Mukherjee Nagar, Delhi, 110009</p>

                  {/*  60% Off */}

                  <div className="rounded-3xl border border-gray-100 shadow-sm py-8">
                    <div className="relative">
                      <div className="absolute top-0 left-0 bg-orange-500 w-1 h-8" />
                      <div className="px-8">
                        <h2 className="text-xl font-bold font-heading mb-6">
                          60% Scholarship Programme for SC/ST/BPL/OBC/Minorities
                        </h2>
                        <p>
                          Providing coaching to the students of SC/ST/BPL/OBC/Minorities students, under this program, 60% scholarship is provided in the fees (Online/Offline) for people residing in India (100 students per Year). The following certificates are required to be submitted to take advantage of this program.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br>
                    </br>
                  </div>

                  {/* 50% off*/}

                  <div className="rounded-3xl border border-gray-100 shadow-sm py-8">
                    <div className="relative">
                      <div className="absolute top-0 left-0 bg-orange-500 w-1 h-8" />
                      <div className="px-8">
                        <h2 className="text-xl font-bold font-heading mb-6">
                          50% Scholarship Programme for EWS.
                        </h2>
                        <p>
                          Under this program, 50% scholarship in fees is provided for coaching (online/offline) to unreserved category of Economically Weaker Students (EWS) living in India having annual income of less than Rs. 8 lakhs (only for 100 students per year). The following certificates are required to be submitted to take advantage of this program.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br>
                    </br>
                  </div>

                  {/* 40% off */}

                  <div className="rounded-3xl border border-gray-100 shadow-sm py-8">
                    <div className="relative">
                      <div className="absolute top-0 left-0 bg-orange-500 w-1 h-8" />
                      <div className="px-8">
                        <h2 className="text-xl font-bold font-heading mb-6">
                          40% Scholarship Programme for Meritorious Students
                        </h2>
                        <p>
                          Under this program, 40% scholarship in fees is provided for coaching (online/offline) to Meritorious Students (only for 50 students per year). The following documents are required to be submitted to take advantage of this program.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br>
                    </br>
                  </div>
                  <div className="sm:hidden ">
                    <div className="rounded-3xl border border-gray-100 shadow-sm py-8 ">
                      <div className="relative">
                        <div className="absolute top-0 left-0 bg-orange-500 w-1 h-8" />
                        <div className="px-8">
                          <h2 className="text-xl font-bold font-heading mb-6">Technical Help desk</h2>
                          <a className="w-full sm:w-auto h-12 py-3 px-5 rounded-full bg-purple-900 border border-purple-700 hover:bg-purple-800 focus:ring focus:ring-purple-800 transition duration-200 inline-flex items-center justify-center gap-2" href="#">
                            <span className="text-white text-sm font-semibold">Visit FAQ Center</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                              <path d="M5 10H15.4167M15.4167 10L10.4167 5M15.4167 10L10.4167 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <form onSubmit={handleSubmit}>
                  <div className="border border-gray-100 rounded-3xl p-6 max-w-xl mx-auto">
                    <h2 className="text-3xl text-center font-bold font-heading mb-4">Register now</h2>
                    <p className="text-center text-gray-600 mb-16">Lets Begin for your journey...</p>
                    <div className="flex flex-wrap -mx-4">
                      <div className="w-full lg:w-1/2 p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="textInput1">First name</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="textInput1" type="text" placeholder="First name" />
                      </div>
                      <div className="w-full lg:w-1/2 p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="textInput2">Last name</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="textInput2" type="text" placeholder="Last name" />
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="mobile">Mobile Number</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="mobile" name="mobile" type="text" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} />
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="email">Email</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="email" name="email" type="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block">Gender</label>
                        <select className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" name="gender" palceholder="Select Your Gender" value={formData.gender} onChange={handleChange}>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="category">Category</label>
                        <select className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" name="category" placeholder="Select your category" value={formData.category} onChange={handleChange}>
                          <option value="EWS">EWS</option>
                          <option value="sc">SC</option>
                          <option value="st">ST</option>
                          <option value="bpl">BPL</option>
                          <option value="OBC">Other Backword Classes (OBC)</option>
                        </select>
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="medium">Medium</label>
                        <select className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" name="medium" placeholder="Select your Prefrance" value={formData.medium} onChange={handleChange}>
                          <option value="english">English</option>
                          <option value="hindi">Hindi</option>
                        </select>
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="mode">Mode</label>
                        <select className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" name="mode" placeholder=" Select Your Learning Mode" value={formData.mode} onChange={handleChange}>
                          <option value="Offline">Offline</option>
                          <option value="Online">Online</option>
                        </select>
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="familyincome">Family Income</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="familyincome" name="familyincome" type="text" placeholder="Family Income Annualy" value={formData.familyincome} onChange={handleChange} />
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block" htmlFor="education_qualification">Education Qualification</label>
                        <input className="w-full rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="education_qualification" name="education_qualification" type="text" placeholder="Education Qualification" value={formData.education_qualification} onChange={handleChange} />
                      </div>
                      <div className="w-full p-4">
                        <label className="text-sm font-medium mb-2 block">Accept Terms</label>
                        <input className="rounded-full p-4 outline-none border border-gray-100 shadow placeholder-gray-500 focus:ring focus:ring-orange-200 transition duration-200" id="accept" name="accept" type="checkbox" checked={formData.accept} onChange={handleChange} />
                        <label className="text-sm font-medium ml-2" htmlFor="accept">I accept the terms and conditions</label>
                      </div>
                    </div>
                      <span>We process your information in accordance with our </span>
                      <span className="text-orange-500 text-sm font-semibold">Privacy Policy</span>
                      <div>
                        <br>
                        </br>
                      </div>
                      <button className="py-4 px-6 rounded-full w-full h-14 inline-flex items-center justify-center text-center mb-8 bg-red-500 border border-red-600 font-bold font-heading text-white hover:bg-red-600 focus:ring focus:ring-red-200 transition duration-200" type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
