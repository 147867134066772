import React from 'react';

export default function Header() {
  return (
    <React.Fragment>
      <>
        <section className="overflow-hidden relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 top-0 w-full">
            <div className="flex justify-center gap-6">
              <div>
                <div className="hidden xl:block w-80 h-80 rounded-3xl mb-6" style={{ background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%)' }} />
              </div>
              <div className="hidden md:block w-80 h-80 rounded-3xl" style={{ background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, rgba(248, 248, 248, 0.50) 100%)' }} />
              <div className="hidden md:block w-80 h-80 rounded-3xl" style={{ background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, rgba(248, 248, 248, 0.50) 100%)' }} />
              <div>
                <div className="hidden xl:block w-80 h-80 rounded-3xl mb-32" style={{ background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%)' }} />
              </div>
            </div>
          </div>
          <div className="relative " style={{ height: 400 }}>
            <div className="flex justify-center mt-14">
            </div>
            <h1 className="text-center text-5xl lg:text-7xl font-bold font-heading mb-6 max-w-2xl mx-auto"> Your Bridge to Success</h1>
            <p className="text-center text-lg mb-10 max-w-xl mx-auto">Spark passion, master governance, shape India's future.</p>
            <div className="flex justify-center"><a className="w-full sm:w-auto text-center h-16 inline-flex items-center justify-center py-4 px-6 rounded-full bg-red-600 border  shadow font-bold font-heading text-white hover:bg-red-500 text-black focus:ring focus:ring-red-200  transition duration-200" href="/Contact">Study Together Now</a></div>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

