import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../components/headers/Header';
import FreeResources from '../components/resources/FreeResources';
import Courses from '../components/courses/Courses';
import Testimonials from '../components/testimonials/Testimonials';
import CallToAction from '../components/call-to-action/CallToAction';
import Faq from '../components/faq/Faq';
import Slider from '../components/Banner-Slider/Slider';
import Admission from '../components/admission-form/admission';
const meta = {
  title: 'Dikshant IAS',
  meta: [
    { name: 'title', content: 'Dikshant IAS'},
    { name: 'description', content: 'This is a learning app developed by Dikshant.' },
    { name: 'keywords', content: 'Learning, Education, App' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'robots', content: 'index, follow' },
    { name: 'author', content: 'DikshantIAS' },
    { property: 'og:title', content: 'Dikshant Learning App' },
    { property: 'og:description', content: 'This is a learning app developed by Dikshant.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://dikshantlearningapp.com/' },
    { property: 'og:image', content: '#' },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:title', content: 'Dikshant Learning App' },
    { property: 'twitter:description', content: 'This is a learning app developed by Dikshant.' },
    { property: 'twitter:url', content: 'https://dikshantlearningapp.com/' },
    { property: 'twitter:image', content: '#' },
    { property: 'twitter:creator', content: '@DikshantIAS' },
    { property: 'twitter:site', content: '@DikshantIAS' },
    { property: 'twitter:domain', content: 'https://dikshantlearningapp.com/' },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:site_name', content: 'Dikshant Learning App' },
    { property: 'og:locale', content: 'en_IN' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Header />
    <FreeResources />
    
      <Courses />
      <Testimonials />
      <CallToAction />
    {
     // <Faq />
    }
    </React.Fragment>
  );
}

