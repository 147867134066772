import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../createClient';
import NotFoundPage from './NotFoundPage';
import Courses from '../components/courses/Courses';

const CourseDetailPage = () => {
  const { slug } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedCourses, setRelatedCourses] = useState([]);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('Course Details')
          .select('*')
          .eq('slug', slug);

        if (error) {
          throw error;
        }

        if (data.length === 0) {
          throw new Error('Course not found.');
        } else if (data.length > 1) {
          throw new Error('Multiple courses found with the same slug.');
        } else {
          setCourse(data[0]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [slug]);

  useEffect(() => {
    const fetchRelatedCourses = async () => {
      try {
        const { data, error } = await supabase
          .from('Course Details')
          .select('*')
          .neq('slug', slug) // Exclude the current course
          .limit(3); // Fetch 3 related courses

        if (error) {
          throw error;
        }

        setRelatedCourses(data);
      } catch (error) {
        console.error('Error fetching related courses:', error);
      }
    };

    if (course) {
      fetchRelatedCourses();
    }
  }, [course, slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!course) {
    return <NotFoundPage />;
  }

  return (
    <section className="overflow-hidden py-24 bg-white">
      <div className="container mx-auto px-4">
        <a
          className="text-gray-500 group mb-12 inline-flex items-center gap-2 flex-wrap hover:text-gray-700 transition duration-200"
          href="/"
        >
          <div className="transition duration-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.4167 10H5M5 10L10 5M5 10L10 15"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <span className="transition duration-200 font-bold">
            Back to Page
          </span>
        </a>

        {/* Course Details Section */}
        <div className="flex flex-wrap -mx-4 mb-10 shadow-md">
          <div className="w-full lg:w-1/2 p-4">
            <div className="py-7">
              {/* Course Title */}
              <h1 className="text-4xl lg:text-4xl font-bold mb-2 text-gray-800">
                {course.Title}
              </h1>
              <br />
              {/* Course Duration and Fees */}
              <div className="flex items-center gap-3">
                <div className="rounded-md bg-gray-200 py-1 px-3">
                  <span className="text-gray-700 text-xs font-medium">
                    Duration: {course.Duration}
                  </span>
                </div>
                <div className="flex items-center gap-3 font-bold text-red-500">
                  <div className="h-6 w-px bg-gray-300"></div>
                  <span>Fees: {course.Fees}</span>
                </div>
              </div>
              <br />

              {/* Buy Button */}
              <a
                href={course.BuyURL}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <button className="bg-red-600 text-white hover:bg-red-700 py-3 px-9 text-center rounded-full border border-gray-200 shadow text-sm font-semibold focus:ring focus:ring-orange-200 transition duration-200">
                  Buy Now
                </button>
              </a>
            </div>
          </div>
          {/* Course Image Section */}
          <div className="w-full lg:w-1/2 p-4">
            <img
              className="w-full h-full object-contain rounded-2xl shadow-lg"
              src={course.CourseImage}
              alt={course.Title}
            />
          </div>
        </div>

        {/* Detailed Course Overview */}
        <div className="flex flex-wrap -mx-4">
          {/* Modules Section */}
          <div className="w-full lg:w-1/3 p-4">
            <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-gray-800">
              Course Content
            </h2>
            <div className="space-y-6">
              {[1, 2, 3].map((index) => (
                <div
                  key={index}
                  className="border bg-white border-gray-200 p-6 rounded-lg transition hover:shadow-md"
                >
                  <h3 className="text-lg font-medium mb-2 text-gray-900">
                    Module {index}: {course[`ModuleHeading_${index}`]}
                  </h3>
                  <p className="text-gray-700">
                    {course[`Module_${index}`]}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Detailed Overview Section */}
          <div className="w-full lg:w-2/3 p-4">
            <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-gray-800">
              Course Overview
            </h2>
            <p className="text-gray-700 font-medium text-lg mb-6">
              {course.CourseOverview}
            </p>

            {/* Learning Outcomes */}
            <div className="mb-8">
              <h3 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                Learning Outcomes
              </h3>
              <ul className="list-disc list-inside text-gray-700">
                {[1, 2, 3, 4, 5].map((index) => (
                  <li key={index}>{course[`KeyFeature_p${index}`]}</li>
                ))}
              </ul>
            </div>

            {/* Key Features */}
            <div className="mb-8">
              <h3 className="text-xl lg:text-2xl font-bold mb-4 text-gray-800">
                {course.Title}'s Key Features
              </h3>
              <ul className="list-disc list-inside text-gray-700">
                {[1, 2, 3].map((index) => (
                  <li key={index}>{course[`LearningOutcome_${index}`]}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Purchase Section */}
        <div className="w-full p-4 mb-12">
          <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-gray-800">
            How to Enroll
          </h2>
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col lg:flex-row items-center lg:items-start">
            <div className="w-full lg:flex-grow lg:w-auto">
              <h3 className="text-lg font-semibold mb-2 text-gray-900">
                Ready to enroll in {course.Title} Foundation Course?
              </h3>
              <p className="text-gray-700">
                Click below to start your learning journey today!
              </p>
            </div>
            <div className="w-full lg:w-auto lg:ml-auto mt-4 lg:mt-0">
              <a href={course.BuyURL} target="_blank" rel="noopener noreferrer">
                <button className="bg-red-500 text-white hover:bg-red-600 py-3 px-5 text-center rounded-full border border-gray-200 shadow text-sm font-semibold focus:ring focus:ring-orange-200 transition duration-200">
                  Enroll Now
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* Related Courses Section */}
        <div className="w-full p-4 mb-12">
          <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-gray-800">
            Related Courses
          </h2>
          <div className="flex flex-wrap -mx-4">
            {relatedCourses.map((relatedCourse) => (
              <div key={relatedCourse.id} className="w-full md:w-1/2 lg:w-1/3 p-4">
                <div className="bg-white border border-gray-300 rounded-lg shadow-md p-6 transition hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 duration-300 ease-in-out">
                  <div className="w-full lg:w-full p-1">
                    <img
                      className="w-full h-full object-contain rounded-3xl"
                      src={relatedCourse.CourseImage}
                      alt={relatedCourse.Title}
                    />
                    <h3 className="text-lg font-semibold mb-2 text-gray-900 mt-4">
                      {relatedCourse.Title}
                    </h3>
                    <p className="text-gray-700 mb-4">
                      {relatedCourse.description}
                    </p>
                    <a
                      href={`/${relatedCourse.slug}`}
                      className="mt-4 block width-full"
                    >
                      <button className="mt-4 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-500 transition duration-300 ease-in-out">
                        Explore Course
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </section>
  );
};

export default CourseDetailPage;