import React, { useState, useEffect } from 'react';

export default function Testimonials() {
    const testimonials = [
        {
            video: "https://www.youtube.com/embed/ebOCU1TilSU"
        },
        {
            video: "https://www.youtube.com/embed/jhtHC2h7tOw" 
        },
        {
            video: "https://www.youtube.com/embed/kN--aIAFcmA" 
        },
        {
            video: "https://www.youtube.com/embed/rZnIUN_Esas" 
        },
        {
            video: "https://www.youtube.com/embed/U_jNpBZyfZ0" 
        },
        {
            video: "https://www.youtube.com/embed/opCRkoxRJwg" 
        },
        {
            video: "https://www.youtube.com/embed/UA6c79YB2rc" 
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleCount, setVisibleCount] = useState(3);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVisibleCount(1);
            } else if (window.innerWidth <= 1024) {
                setVisibleCount(2);
            } else {
                setVisibleCount(3);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 2000);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('resize', handleResize);
        };
    }, [testimonials.length]);

    const getVisibleTestimonials = () => {
        let visibleTestimonials = [];
        for (let i = 0; i < visibleCount; i++) {
            visibleTestimonials.push(testimonials[(currentIndex + i) % testimonials.length]);
        }
        return visibleTestimonials;
    };

    return (
        <React.Fragment>
            <style jsx>{`
                .slide-container {
                    display: flex;
                    transition: transform 500ms ease-in-out;
                    gap: 20px;
                }
                .testimonial-card {
                    min-width: calc((100% / 3) - 20px);
                    margin: 0 10px;
                }
                @media (max-width: 1024px) {
                    .testimonial-card {
                        min-width: calc((100% / 2) - 20px);
                    }
                }
                @media (max-width: 768px) {
                    .testimonial-card {
                        min-width: 100%;
                        margin: 0;
                    }
                    .slide-container {
                        gap: 10px;
                    }
                }
                .video-container {
                    width: 297px;
                    height: 224px;
                    overflow: hidden;
                    background: #000;
                    margin: 0 auto;
                }
                .video-container iframe {
                    width: 100%;
                    height: 100%;
                }
            `}</style>
            <section className="py-24 relative overflow-hidden">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl lg:text-5xl text-center font-bold font-heading max-w-md lg:max-w-xl mx-auto mb-24">
                        Hear words for us from our toppers
                    </h1>
                    <div className="h-96 mb-32 lg:mb-16 relative overflow-hidden">
                        <div className="slide-container" style={{ transform: `translateX(-${currentIndex * (100 / visibleCount)}%)` }}>
                            {testimonials.concat(testimonials.slice(0, visibleCount)).map((testimonial, index) => (
                                <div
                                    key={index}
                                    className="testimonial-card border border-gray-100 shadow rounded-3xl p-8 w-104 h-80"
                                >
                                    <div className="video-container rounded-3xl">
                                        <iframe
                                            src={testimonial.video}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
