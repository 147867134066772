import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import Navbar from './components/navbar/Navbar.js';
import IndexPage from './pages/Index.js';
import NotFoundPage from './pages/NotFoundPage.js';
import LoginPage from './pages/Login.js';
import RegisterPage from './pages/Register.js';
import Footer from './components/footers/Footer.js';
import ScholarshipForm from './pages/Scholarship-form.js';
import Contact from './pages/Contact.js';
import CourseDetailedPage from './pages/CourseDetailedPage.js';
import OfflineMode from './pages/Offline-Course.js';
import OnlineMode from './pages/Online-Courses.js';
import AboutUs from './pages/About.js';
import CareerPage from './pages/Career.js';
import SelectionPage from './pages/Selections.js';
import WhatToReadInHindu from './pages/WhatToReadInHindu.js';
import WhatToReadinIndianExpress from './pages/WhatToReadInIndianExpress.js';
import QuickFactOfTheDay from './pages/QuickFactOfTheDay.js';
import EditorialAnalysis from './pages/EditorialAnalysis.js';
import DailyCurrentAffairsAnalysis from './pages/DailyCurrentAffairsAnalysis.js';
import DailyAnswerWriting from './pages/DailyAnswerWriting.js';
import ArticlesDetailedPage from './pages/ArticlesDetailedPage.js';


function App() {
  return (
    <>
    <Navbar />
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/selections" element={<SelectionPage />} />
      <Route path="/career" element={<CareerPage />} />
      <Route path="/daily-answer-writing" element={<DailyAnswerWriting />} />
      <Route path="/daily-current-affairs-analysis" element={<DailyCurrentAffairsAnalysis />} />
      <Route path="/editorial-analysis" element={<EditorialAnalysis />} />
      <Route path="/facts-of-the-day" element={<QuickFactOfTheDay />} />
      <Route path="/what-to-read-in-the-hindu" element={<WhatToReadInHindu />} />
      <Route path="/what-to-read-in-indian-express" element={<WhatToReadinIndianExpress />} />
      <Route path="/offline-mode" element={<OfflineMode />} />
      <Route path="/online-mode" element={<OnlineMode />} />
      <Route path="/Scholarship" element={<ScholarshipForm/>} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/:slug" element={<CourseDetailedPage />} />
      <Route path="/article/:id" element={<ArticlesDetailedPage />} /> 
      <Route path="/what-to-read-in-the-Hindu/:id" element={<ArticlesDetailedPage />} />
      <Route path="/editorial-analysis/:id" element={<ArticlesDetailedPage />} />
      <Route path="/facts-of-the-day/:id" element={<ArticlesDetailedPage />} /> 
      <Route path="/what-to-read-in-indian-express/:id" element={<ArticlesDetailedPage />} /> 
      <Route path="/daily-current-affairs-analysis/:id" element={<ArticlesDetailedPage />} />
      <Route path="/:title" element={<SelectionPage />} />
      <Route path="/selections" element={<SelectionPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
