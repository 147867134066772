import React from 'react';

const CareerPage = () => {
    return (
        <div className="container mx-auto py-12 px-6">

            {/* Current Openings Section */}
            <div className="mb-16 text-center">
                <h2 className="text-4xl font-semibold text-gray-800 mb-8">Current Openings</h2>
                <p className="text-lg text-gray-700">There are currently no job openings available. Please check back later or contact us for future opportunities.</p>
            </div>

            {/* Why Work With Us Section */}
            <div className="mb-16">
                <h2 className="text-4xl font-semibold text-gray-800 mb-8">Why Work With Us?</h2>
                <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                    <p className="text-lg text-gray-700 leading-relaxed mb-6">
                        At Dikshant IAS, we believe in fostering a culture of growth, innovation, and collaboration. Our team is dedicated to creating an environment where you can thrive professionally and personally.
                    </p>
                    <ul className="list-disc list-inside space-y-4 text-gray-700">
                        <li>Flexible working hours and remote work options.</li>
                        <li>Continuous learning and development opportunities.</li>
                        <li>Inclusive and diverse work environment.</li>
                        <li>Competitive salary and benefits packages.</li>
                    </ul>
                </div>
            </div>

            {/* Contact Us Section */}
            <div className="text-center">
                <h2 className="text-4xl font-semibold text-gray-800 mb-6">Contact Us</h2>
                <p className="text-lg text-gray-600 leading-relaxed mb-6">
                    If you're interested in working with us or have any questions, feel free to reach out.
                </p>
                <a
                    className="py-3 px-5 w-full text-center rounded-full bg-black border border-purple-100 shadow text-sm font-semibold text-white hover:bg-black-800 focus:ring focus:ring-purple-800 transition duration-200"
                    href="mailto:hiring@dikshantias.com"
                >
                   Join Our team
                </a>
            </div>
        </div>
    );
};

export default CareerPage;
