import React, { useState, useEffect } from 'react';
import supabase from '../createClient';

export default function Courses() {
    const [courses, setCourses] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCourses(selectedCategory);
        fetchCategories();
    }, [selectedCategory]);

    const fetchCourses = async (category) => {
        try {
            let { data, error } = await supabase
                .from('Course Section') // Assuming the table name includes both offline and online courses
                .select('*')
                .eq('mode', 'offline'); // Default to offline mode

            if (error) throw error;

            if (category) {
                data = data.filter(course => course.category === category);
            }
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            let { data, error } = await supabase
                .from('Course Section')
                .select('category')
                .group('category');

            if (error) throw error;

            setCategories(data.map(item => item.category));
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const filterCourses = (category) => {
        setSelectedCategory(category);
    };

    return (
        <React.Fragment>
            <style jsx>{`
                .description {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .title {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            `}</style>
            <div className="container px-4 mx-auto">
                <h1 className="text-4xl lg:text-5xl font-bold font-heading text-center mb-6 mt-16"> Our offline Programs and Courses</h1>
                <p className="text-gray-500 text-center mb-20">Discover the Offline courses and programmes from Dikshant IAS.</p>
                
                <div className="flex flex-wrap justify-center gap-4 mb-10">
                    {categories.map((category) => (
                        <button
                            key={category}
                            className={`h-10 inline-flex items-center justify-center w-full sm:w-auto text-center py-3 px-4 rounded-full ${selectedCategory === category ? 'bg-black text-white' : 'bg-white border border-gray-200 text-black'}`}
                            onClick={() => filterCourses(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <div className="flex flex-wrap -mx-4">
                    {courses.map((course) => (
                        <div key={course.id} className="w-full md:w-1/2 lg:w-1/3 p-4">
                            <a href={course.ViewMore}>
                                <div className="bg-white border border-gray-200 hover:border-red-500 transition duration-200 rounded-2xl h-full p-3">
                                    <div className="relative h-60 overflow-hidden rounded-2xl">
                                        <img
                                            className="w-full h-full object-contain"
                                            src={course.thumbnail}
                                            alt={course.title}
                                        />
                                    </div>
                                    <div className="py-6">
                                        <div className="flex flex-wrap items-center gap-3 mb-3">
                                            <p className="py-1 px-2 rounded-md bg-red-500 border border-gray-100 text-sm font-medium text-white">{course.date}</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 4 4" fill="none">
                                                <line x1="2" y1="0" x2="2" y2="4" stroke="#B8B8B8" strokeWidth="0.5" />
                                            </svg>
                                            <div className="py-1 px-2 rounded-md border border-gray-100 text-xs font-medium text-gray-700">{course.category}</div>
                                        </div>
                                        <h2 className="text-xl font-bold font-heading mb-3 max-w-xs max-w-xl title">{course.title}</h2>
                                        <p className="text-gray-500 mb-3 text-sm description">{course.description}</p>
                                        <div className="flex gap-4 mx-4">
                                            <a className="h-10 inline-flex items-center mt-4 justify-center w-full sm:w-auto text-center py-3 px-4 rounded-full bg-red-600 hover:bg-red-500 text-white text-sm font-semibold transition duration-200" href={course.ViewMore}>View More</a>
                                            <a className="h-10 inline-flex items-center mt-4 justify-center w-full sm:w-auto text-center py-3 px-4 rounded-full bg-red-600 hover:bg-red-500 text-white text-sm font-semibold transition duration-200" href={course.BuyURL} target="_blank">Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
